import axios from 'axios';
import K from '../constants';

let baseURL = process.env[K.API_URL];
const isLocal = process.env[K.IS_LOCAL];

baseURL = isLocal ? baseURL : `${baseURL}/api`;

export default axios.create({
	baseURL,
});
