import React from 'react';
import { AppBar, Toolbar, Hidden, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import clsx from 'clsx';
import { connect, ConnectedProps } from 'react-redux';
import { useCookies } from 'react-cookie';
import { logoutUser } from '../../store/modules/auth.reducer';
import { RootState } from '../../store/root';
import K from '../../constants';

const useStyles = makeStyles ({
	root: {
		backgroundColor: '#fff',
		color: 'black',
		zIndex: 12,
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		'@media (max-width: 899px)': {
		    display: 'none',
		},
	},
	avatar: {
		background: '#0a66c2!important',
		color: 'white!important',
		'@media (min-width: 2200px)': {
			width: '1.5em',
			height: '1.5em',
			fontSize: '2rem',
		},
	},
	profile: {
		textTransform: 'capitalize!important',
		fontFamily: 'Lato!important',
		color: 'black',
		'@media (min-width: 2500px)': {
			fontSize: '1.4rem',
		},
	},
	navLink: {
		fontWeight: 'bold',
		fontFamily: 'inherit',
		color: 'black',
		textDecoration: 'none!important',
		marginLeft: '2rem',
		letterSpacing: '1px',
		'&:first-child': {
			marginLeft: '0',
		},
	},
	activeLink: {
		borderBottom: `2px solid #0a66c2`,
	},
	loginButton: {
		padding: '.5rem 1rem',
		background: '#0a66c2',
		color: '#fff',
		borderRadius: '5px',
	},
	logo: {
	    display: 'grid',
	    fontSize: '11px',
	    fontWeight: 'bold',
	    margin: '3px 0px',
	    textDecoration: 'none!important',
	    letterSpacing: '0px',
	},
	profileIcon: {
	    display: 'flex',
	    alignItems: 'center',
	    textAlign: 'center',
	},
});

interface HeaderProps {
	openMobileNav: (event?: any) => void;
}

type Props = ConnectedProps<typeof connector> & HeaderProps;

const Header: React.FC<Props> = ({ openMobileNav, auth, logoutUser }) => {
	const styles = useStyles();
	const [, , removeCookies] = useCookies();
	const history = useHistory();
	const location = useLocation();
	// const { theme, toggleTheme } = useContext(ThemeContext);

	const logout = () => {
		logoutUser(removeCookies);
		localStorage.clear();
		history.push('/');
	};

	const navShown = () => {
		return (
			location.pathname === '/' ||
			location.pathname === '/about/' ||
			location.pathname === '/contact/' ||
			location.pathname === '/pricing/' ||
			location.pathname === '/privacy-policy/' ||
			location.pathname === '/terms-of-service/' ||
			location.pathname === '/thank-you/' ||
			location.pathname === '/supported-counties/' ||
			location.pathname === '/refund-policy/' ||
			location.pathname === '/404/' ||
			location.pathname === '/500/' ||
			location.pathname === '/refer-a-friend/' ||
			location.pathname === '/lower-my-property-taxes/'
		);
	};

	const authenticatedLinksHidden =
		location.pathname === '/' ||
		location.pathname === '/login/' ||
		location.pathname === '/properties/report-summary' ||
		location.pathname === '/about/' ||
		location.pathname === '/contact/' ||
		location.pathname === '/pricing/' ||
		location.pathname === '/privacy-policy/' ||
		location.pathname === '/terms-of-service/' ||
		location.pathname === '/magicAuth/' ||
		location.pathname === '/supported-counties/';

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
	    setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
	    setAnchorEl(null);
	};

	// const changeTheme = (e: any) => {
	// 	toggleTheme(e.target.checked ? 'dark' : 'light');
	// };

	return (
		<AppBar className={styles.root}>
			<Toolbar className={styles.toolbar}>
				<NavLink to='/' className={styles.logo}>
					<img
					    src='https://res.cloudinary.com/agiliti/image/upload/v1638251812/SDLogo.webp'
					    alt='Square Deal'
					    width='150'
					    height='60'
					    title='Square Deal'
					/>
					Property Tax Appeal - Do it Yourself. Do it Right.
				</NavLink>
				<div>
					<Hidden smDown>
						<div className={styles.row}>
							{auth.authenticated && !authenticatedLinksHidden ? (
								<>
									{/* <Switch
										title="Toggle Dark theme"
										color="primary"
										checked={theme === 'dark'}
										onChange={changeTheme}
									/> */}
									{/* <IconButton title='Logout' onClick={logout}>
										<ExitIcon />
									</IconButton> */}
									<Typography
										className={clsx(styles.row, styles.profile)}
										variant='button'
										// onClick={() => history.push('/profile')}
									>
										<span sx={{marginRight:'10px'}}>
											{auth.user?.display_name ||
												window.localStorage.getItem(K.SAVED_USER_EMAIL_ADDRESS)}
										</span>
									</Typography>

									<Box className={styles.profileIcon}>
									    <Tooltip title="My account">
									        <IconButton
									            onClick={handleClick}
									            size="small"
												sx={{ ml: 2 }}
									            aria-controls={open ? 'account-menu' : undefined}
									            aria-haspopup="true"
									            aria-expanded={open ? 'true' : undefined}
									        >
												{auth.user?.profile_pic ? (
													<Avatar className={styles.avatar}
															sx={{ width: 35, height: 35 }}
															src={auth.user.profile_pic} />
												) : (
													<Avatar className={styles.avatar} color='blue'
															sx={{ width: 35, height: 35 }}
															src={auth.user.profile_pic}
													>
														{auth.user?.display_name
															? auth.user?.display_name.substring(0, 2).toUpperCase()
															: window.localStorage
																.getItem(K.SAVED_USER_EMAIL_ADDRESS)
																?.substring(0, 2)
																?.toUpperCase()}
													</Avatar>
												)}
									        </IconButton>
									    </Tooltip>
									</Box>
									<Menu
									    anchorEl={anchorEl}
									    id="account-menu"
									    open={open}
									    onClose={handleClose}
									    onClick={handleClose}
									    PaperProps={{
									        elevation: 0,
									        sx: {
									            overflow: 'visible',
									            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
									            mt: 1.5,
									            '& .MuiAvatar-root': {
									                width: 32,
									                height: 32,
									                ml: -0.5,
									                mr: 1,
									            },
									            '&:before': {
									                content: '""',
									                display: 'block',
									                position: 'absolute',
									                top: 0,
									                right: 14,
									                width: 10,
									                height: 10,
									                transform: 'translateY(-50%) rotate(45deg)',
									                zIndex: 0,
									            },
									        },
									    }}
									    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
									    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
									>
									    <MenuItem title='Logout' onClick={logout} sx={{backgroundColor:'white'}}>
									        <ListItemIcon>
									            <Logout fontSize="small" />
									        </ListItemIcon>
									        Logout
									    </MenuItem>
									</Menu>
								</>
							) : (
								navShown() && (
									<>
										<NavLink
											to='/'
											className={styles.navLink}
											exact
											activeClassName={styles.activeLink}
										>
											Home
										</NavLink>
										<NavLink
											to='/about/'
											className={styles.navLink}
											exact
											activeClassName={styles.activeLink}
										>
											About
										</NavLink>
										<NavLink
											to='/pricing/'
											className={styles.navLink}
											exact
											activeClassName={styles.activeLink}
										>
											Pricing
										</NavLink>
										<a
											href={process.env.REACT_APP_BLOG_URL}
											className={styles.navLink}
											target='_blank'
											rel='noopener noreferrer'
										>
											Blog
										</a>
										<NavLink
											to='/contact/'
											className={styles.navLink}
											exact
											activeClassName={styles.activeLink}
										>
											Contact Us
										</NavLink>
									</>
								)
							)}
						</div>
					</Hidden>
					{location.pathname !== '/properties/report-summary' && location.pathname !== '/login/' && (
						<Hidden mdUp>
							<IconButton onClick={() => openMobileNav()} aria-label="Right Align">
								<MenuIcon />
							</IconButton>
						</Hidden>
					)}
				</div>
			</Toolbar>
		</AppBar>
	);
};

const mapStateToProps = (state: RootState) => ({
	auth: state.auth,
});

const connector = connect(mapStateToProps, { logoutUser });

export default connector(Header);