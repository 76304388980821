const constants = {
	API_URL: 'REACT_APP_API_URL',
	PUBLIC_URL: 'REACT_APP_PUBLIC_URL',
	ACCESS_TOKEN: 'SQUARE_DEAL_UI_B2C_ACCESS_TOKEN',
	SAVED_USER: 'SAVED_USER',
	STORAGE_KEYS: {
		THEME_KEY: 'SQUARE_DEAL_UI_B2C_THEME_KEY',
	},
	IS_LOCAL: 'REACT_APP_IS_LOCAL',
	IDLE_TIME_MINUTES: process.env.REACT_APP_IDLE_TIMEOUT
		? parseInt(process.env.REACT_APP_IDLE_TIMEOUT)
		: 30, // minutes for calculating idle time
	EVIDENCE_PRICE: process.env.REACT_APP_EVIDENCE_PRICE
		? parseFloat(process.env.REACT_APP_EVIDENCE_PRICE).toFixed(2)
		: 29, // minutes for calculating idle time
	BULK_ENQUIRY_MIN_PROPERTIES: process.env.REACT_APP_BULK_ENQUIRY_MIN_PROPERTIES? parseInt(process.env.REACT_APP_BULK_ENQUIRY_MIN_PROPERTIES): 10,
	BULK_ENQUIRY_MAX_PROPERTIES: process.env.REACT_APP_BULK_ENQUIRY_MAX_PROPERTIES? parseInt(process.env.REACT_APP_BULK_ENQUIRY_MAX_PROPERTIES): 100,
	BULK_ENQUIRY_DEFAULT_PROPERTIES: process.env.REACT_APP_BULK_ENQUIRY_DEFAULT_PROPERTIES? parseInt(process.env.REACT_APP_BULK_ENQUIRY_DEFAULT_PROPERTIES): 10,
	BULK_ENQUIRY_STEP: process.env.REACT_APP_BULK_ENQUIRY_STEP? parseInt(process.env.REACT_APP_BULK_ENQUIRY_STEP): 10,
	SAVED_USER_ID: 'SAVED_USER_ID',
	SAVED_ACCOUNT_NUMBER: 'SAVED_ACCOUNT_NUMBER',
	SAVED_COUNTY_ID: 'SAVED_COUNTY_ID',
	SAVED_COUNTY_NAME: 'SAVED_COUNTY_NAME',
	SAVED_CITY: 'SAVED_CITY',
	SAVED_STATE_CODE: 'SAVED_STATE_CODE',
	SAVED_COMPARABLES_SUMMARY: 'SAVED_COMPARABLES_SUMMARY',
	SAVED_PROPERTY_OWNER_ID: 'SAVED_PROPERTY_OWNER_ID',
    SAVED_OWNER_NAME: 'SAVED_OWNER_NAME',
	SAVED_USER_EMAIL_ADDRESS: 'SAVED_USER_EMAIL_ADDRESS',
	SAVED_ADDRESS: 'SAVED_ADDRESS',
	SAVED_ZIP_CODE: 'SAVED_ZIP_CODE',
	SAVED_ORDER_STATUS_ID: 'SAVED_ORDER_STATUS_ID',
};

export default constants;
